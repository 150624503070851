import ApiService from './api.service';

export const GET_FORM = 'getFormCampaign';
export const SUBMIT_FORM = 'submitFormCampaign';
export const DYNAMIC_PICKER_LIST = 'getDynamicPickerList';

const state = {};

const getters = {};

const actions = {
  [GET_FORM](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/form/${param.id}`)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SUBMIT_FORM](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/form/${param.id}`, param.data)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DYNAMIC_PICKER_LIST](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/form-picker/${param.id}/${param.parent_id || ''}`, param.query)
        .then(async ({ data }) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};