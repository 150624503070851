import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '../string/en.json';
import id from '../string/id.json';

Vue.use(VueI18n);

const messages = {
  en,
  id,
};

let locale = (navigator.language || navigator.userLanguage).slice(0, 2);

if (!messages[locale]) {
  locale = 'en';
}

export default new VueI18n({
  locale,
  messages,
});
