import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import form from './modules/form';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    form,
  },
  plugins: [createPersistedState()],
});
