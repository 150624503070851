import Vue from 'vue';
import VueRouter from 'vue-router';

// import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/404',
  },
  {
    path: '/form/:id',
    name: 'Form',
    meta: {
      title: 'Microsite',
      layout: 'topOnly',
    },
    component: () => import('../ui/pages/MainForm.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: { layout: 'default', auth: true },
    component: () => import('../ui/pages/error/404.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
